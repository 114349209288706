import store from '@/store'

export function displayText (key, defaultText) {
  if (! store.state.general.dashboardModule) return defaultText;

  let textMapping = store.state.general.dashboardModule.text_mapping ?? [];

  const keys = key.split('.');
  textMapping = textMapping[keys[0]] ?? [];

  if (!Array.isArray(textMapping)) return defaultText;

  const text = textMapping.find(mapping => mapping.key === keys[1]);

  return text ? text.value : defaultText;
}

export function getSampleExcelFile (sampleFile, defaultUrl) {
    if (! sampleFile) {
      window.location.href = `${window.location.origin}${defaultUrl}`
      return
    }

  const url = sampleFile?.url ?? false;
  if (!url) {
    window.location.href = `${window.location.origin}${defaultUrl}`
    return
  }
  window.location.href = url
}
