<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              {{ displayText("data_chart.title", "報表中心") }}
            </b-breadcrumb-item>

            <b-breadcrumb-item active>{{
              displayText("data_chart.staff_join_line_by_month_chart", "業務綁定統計")
            }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div
        class="
          d-flex
          flex-column flex-xl-row
          align-items-start align-items-xl-center
          justify-content-between
          mb-4 mb-xl-2
        "
      >
        <h4
          class="col-12 col-xl-4 mb-2 mb-xl-0 font-weight-bold"
        >
          {{ displayText("data_chart.staff_join_line_by_month_chart", "業務綁定統計") }}
        </h4>
        <div class="row-searchbar d-flex mb-2 mb-md-0 mr-md-2">

            <b-button class="ml-2" variant="primary" @click="handleExport"
            >{{ displayText('data_chart.partner_worker_join_line_by_month_chart_export', '匯出') }}</b-button
            >
            <b-button
              v-b-toggle.collapse-1-inner
              variant="link"
              class="flex-shrink-0 ml-0 ml-xl-1"
            >
              {{ displayText("data_chart.partner_worker_join_line_by_month_chart_export_search_advanced", "進階搜尋")
              }}<i class="fa fa-chevron-down"></i>
            </b-button>
        </div>
    </div>
    <b-collapse id="collapse-1-inner" class="mt-2">
      <div class="border p-2">
        <b-form>
          <div class="d-flex flex-column flex-xl-row">
            <b-form-group
              v-bind:label="displayText('data_chart.staff_join_line_by_month_dept_name_search', 'Division')"
              label-cols-sm="3"
              label-cols-lg="2"
              style="width: 100%"
            >
                <AppMultiSelect
                v-model="selectedDeptName"
                :options="deptNameOptions"
                />
            </b-form-group>

            <b-form-group
              v-bind:label="displayText('data_chart.staff_join_line_by_month_job_specialty_search', 'Specialty')"
              label-cols-sm="3"
              label-cols-lg="2"
              style="width: 100%"
            >
                <AppMultiSelect
                    v-model="selectedJobSpecialty"
                    :options="jobSpecialtyOptions"
                />
            </b-form-group>
          </div>
          <div class="d-flex flex-column flex-xl-row">
            <b-form-group
              v-bind:label="displayText('data_chart.staff_join_line_by_month_partner_name_search', 'HCO Name')"
              label-cols-sm="3"
              label-cols-lg="2"
              style="width: 100%"
            >
                <AppMultiSelect
                    v-model="selectedPartnerName"
                    :options="partnerNameOptions"
                />
            </b-form-group>

            <b-form-group
              v-bind:label="displayText('data_chart.staff_join_line_by_month_branch_name_search', 'Area')"
              label-cols-sm="3"
              label-cols-lg="2"
              style="width: 100%"
            >
                <AppMultiSelect
                    v-model="selectedBranchName"
                    :options="branchNameOptions"
                />
            </b-form-group>
          </div>
          <div class="d-flex flex-column flex-xl-row">
            <b-form-group
                v-bind:label="displayText('data_chart.patner_worker_join_line_by_month_year_search', 'Year')"
                label-cols-sm="3"
                label-cols-lg="2"
                style="width: 100%"
            >
                <AppMultiSelect
                  v-model="selectedYear"
                  :options="yearOptions"
                />
            </b-form-group>
          </div>

          <div class="flex-column">
            <b-button
              v-b-toggle.accordion-2
              variant="primary"
              class="mb-2 mb-xl-0"
              @click="handleSearch"
              ><i class="fa fa-search"></i
              >{{ displayText("partner.search", "搜尋") }}</b-button
            >
            <b-button
              v-b-toggle.accordion-2
              variant="primary"
              class="mb-2 mb-xl-0"
              @click="resetSearch"
              >{{
                displayText("partner.search_reset", "重置搜尋條件")
              }}</b-button
            >
          </div>
        </b-form>
      </div>
    </b-collapse>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="data"
                :fields="fields"
                show-empty
              >
                <template #cell(dept_name)="data">
                    <div v-if="!data.item.isSubtotal">
                        {{ data.item.dept_name }}
                    </div>
                    <div v-else>
                        <span>SubTotal</span>
                    </div>
                </template>
                <template #cell(staff_branch_name)="data">
                    <div v-if="!data.item.isSubtotal">
                        {{ data.item.staff_branch_name }}
                    </div>
                </template>
                <template #cell(staff_name)="data">
                    <div v-if="!data.item.isSubtotal">
                        {{ data.item.staff_name }}
                    </div>
                </template>
                <template #cell(partner_name)="data">
                    <div v-if="!data.item.isSubtotal">
                        {{ data.item.partner_name }}
                    </div>
                </template>
                <template #cell(job_specialty)="data">
                    <div v-if="!data.item.isSubtotal">
                        {{ data.item.job_specialty }}
                    </div>
                </template>
                <template #cell(YTD)="data">
                    {{ calculateYTD(data.item) }}
                </template>
                <template #empty>
                  <div class="text-center text-muted">
                    <p>查無資料</p>
                  </div>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import AppMultiSelect from "@/components/AppMultiSelect";
import { format } from "date-fns";
import { mapState, mapGetters } from "vuex";
import dataChartApi from "@/apis/data-chart";
import { displayText } from "@/utils/dashboardUtils";

export default {
  components: { AppMultiSelect },
  data() {
    return {
      showLoading: true,
      data: [],
      jobSpecialtyOptions: [],
      deptNameOptions: [],
      partnerNameOptions: [],
      branchNameOptions: [],
      selectedYear: [],
      selectedJobSpecialty: [],
      selectedDeptName: [],
      selectedPartnerName: [],
      selectedBranchName: [],
      query: {
        year: [],
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      dashboardModule: (state) => state.dashboardModule,
    }),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    disabledEndAtDates() {
      return { to: new Date(this.query.start_at) };
    },
    yearOptions() {
      let yearOptions = [];
      const currentYear = new Date().getFullYear();

      for (let year = 2023; year <= currentYear+1; year++) {
        yearOptions.push({
          value: year,
          text: year,
        });
      }

      return yearOptions;
    },
    fields() {
      let fields = [
        {
          key: "dept_name",
          label: displayText("staff.staff_dept_name", "Division"),
        },
        {
          key: "staff_branch_name",
          label: displayText("staff.staff_branch_name", "Area"),
        },
        {
          key: "staff_name",
          label: displayText("staff.staff_name", "MR"),
        },
        {
          key: "partner_name",
          label: displayText("partner.partner_name", "HCO Name"),
        },
        {
          key: "job_specialty",
          label: displayText("staff.job_specialty", "Specialty"),
        },
        {
          key: "1",
          label: displayText("data_chart.jan", "Jan"),
        },
        {
          key: "2",
          label: displayText("data_chart.feb", "Feb"),
        },
        {
          key: "3",
          label: displayText("data_chart.mar", "Mar"),
        },
        {
          key: "4",
          label: displayText("data_chart.apr", "Apr"),
        },
        {
          key: "5",
          label: displayText("data_chart.may", "May"),
        },
        {
          key: "6",
          label: displayText("data_chart.jun", "Jun"),
        },
        {
          key: "7",
          label: displayText("data_chart.jul", "Jul"),
        },
        {
          key: "8",
          label: displayText("data_chart.aug", "Aug"),
        },
        {
          key: "9",
          label: displayText("data_chart.sep", "Sep"),
        },
        {
          key: "10",
          label: displayText("data_chart.oct", "Oct"),
        },
        {
          key: "11",
          label: displayText("data_chart.nov", "Nov"),
        },
        {
          key: "12",
          label: displayText("data_chart.dec", "Dec"),
        },
        {
          key: "YTD",
          label: displayText("data_chart.ytd", "YTD"),
        },
      ];
      return fields;
    },
  },
  watch: {
  },
  async created() {
    const currentYear = new Date().getFullYear();
    for (let year = 2023; year <= currentYear; year++) {
      this.query.year.push(year)
      this.selectedYear.push({
        value:year,
        text:year
      })
    }
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    format,
    displayText,
    handleSearch() {
      this.getData();
    },
    resetSearch() {
      this.query = {
      },
      this.selectedYear = [];
      this.selectedJobSpecialty = [];
      this.selectedDeptName = [];
      this.selectedPartnerName = [];
      this.selectedBranchName = [];
      this.getData();
    },
    calculateColumnTotal(field) {
      return this.data.reduce((total, item) => {
        if (item['isSubtotal']) return total;
        return total + item[field]
      }, 0)
    },
    calculateYTD(item) {
      let total = 0
      for (let i = 1; i <= 12; i++) {
        total += item[i];
      }
      item.ytd = total;
      return total;
    },
    async handleExport() {
      let query = this.query;
      query.year = this.selectedYear.map((year) => year.value);
      query.job_specialty = this.selectedJobSpecialty.map((js) => js.value);
      query.dept_name = this.selectedDeptName.map((dn) => dn.value);
      query.partner_name = this.selectedPartnerName.map((pn) => pn.value);
      query.branch_name = this.selectedBranchName.map((bn) => bn.value);
      dataChartApi.exportStaffJoinLineByMonthChart(query).then(() => {
        this.$swal({
          title: '成功',
          text: "可至“我的主控台”查看紀錄及下載檔案",
          showCancelButton: true,
          cancelButtonText: '確認',
          type: 'success',
          confirmButtonText: '前往查看',
        }).then((result)=>{
            if (result.value) {
              this.$router.push({
                name: "MyConsoleDownloadFileList",
                params: { org_code: this.$route.params.org_code },
              });
            }
          });
      })
    },
    async getData() {
      this.showLoading = true;
      let query = this.query
      query.year = this.selectedYear.map((year) => year.value);
      query.job_specialty = this.selectedJobSpecialty.map((js) => js.value);
      query.dept_name = this.selectedDeptName.map((dn) => dn.value);
      query.partner_name = this.selectedPartnerName.map((pn) => pn.value);
      query.branch_name = this.selectedBranchName.map((bn) => bn.value);
      try {
        const { data } = await dataChartApi.getStaffJoinLineByMonthChart(
          this.query
        );
        this.jobSpecialtyOptions = data.options.job_specialty.filter(
          (item) => item !== null
        ).map((item) => {
          return {
            value: item,
            text: item
          }
        });
        this.deptNameOptions = data.options.dept_name.filter(
          (item) => item !== "Total"
        ).map((item) => {
          return {
            value: item,
            text: item
          }
        });
        this.partnerNameOptions = data.options.partner_name.filter(
          (item) => item !== null
        ).map((item) => {
          return {
            value: item,
            text: item
          }
        });
        this.branchNameOptions = data.options.branch_name.filter(
          (item) => item !== null
        ).map((item) => {
          return {
            value: item,
            text: item
          }
        });
        this.data = data.data
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: displayText("partner.api_failed", "讀取資料錯誤"),
          });
        }
      }
      this.showLoading = false;
    },
  },
};
</script>
<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
.row-searchbar {
  width: 45%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
</style>
