import https from "./https"
import store from "@/store"

const dataChartApi = {
  getPartnerWorkerChart (params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/data-chart/partner-worker`, { params })
  },
  exportPartnerWorkerChart (params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/data-chart/partner-worker-export`, { params })
  },
  getPartnerWorkerJoinLineByMonthChart (params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/data-chart/partner-worker-join-line-by-month`, { params })
  },
  exportPartnerWorkerJoinLineByMonthChart (params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/data-chart/partner-worker-join-line-by-month-export`, { params })
  },
  getStaffJoinLineByMonthChart (params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/data-chart/staff-join-line-by-month`, { params })
  },
  exportStaffJoinLineByMonthChart (params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/data-chart/staff-join-line-by-month-export`, { params })
  },
}

export default dataChartApi
